export const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8080/api';
export const LOGO_URL = process.env.REACT_APP_LOGO_URL;
export const FAVICON_URL = process.env.REACT_APP_FAVICON_URL;
export const LOGO_WHITE_URL = process.env.REACT_APP_LOGO_URL;
export const COPYRIGHT_NAME = process.env.REACT_APP_COPYRIGHT_NAME;
export const PRIVACY_URL = process.env.REACT_APP_PRIVACY_URL;
export const CONTACT_URL = process.env.REACT_APP_CONTACT_URL;
export const COPYRIGHT_URL = process.env.REACT_APP_COPYRIGHT_URL;

export const GRAPH_COLORS = {
	PRIMARY: process.env.REACT_APP_PRIMARY_COLOR,
	GREEN: '#2eb85c',
	RED: '#e55353',
	YELLOW: '#f9b115',
};

export const Roles = {
	0: 'Super Admin',
	1: 'Central Admin',
	2: 'MSSP Admin',
	3: 'End User',
};

export const AllRights = [];

export const Rights = {
	//client related rights
	can_view_clients: [0, 1, 2],
	can_edit_clients: [0, 1, 2],

	//employees related rights
	can_view_employees: [1, 2, 3],
	can_edit_employees: [1, 2, 3],

	//portal users related rights
	can_view_users: [0, 1, 2, 3],
	can_edit_users: [0, 2],

	//org users related rights
	can_view_org_users: [0, 1, 2],
	can_edit_org_users: [0, 1, 2],

	//tempaltes related rights
	can_view_email_templates: [0, 1, 2, 3],
	can_edit_email_templates: [0, 1, 2],
	can_view_sms_templates: [0, 1, 2, 3],
	can_edit_sms_templates: [0, 1, 2],
	can_view_system_templates: [0, 1, 2, 3],
	can_edit_system_templates: [0, 1, 2, 3],
	can_view_voice_templates: [0, 1, 2, 3],
	can_edit_voice_templates: [0, 1, 2],

	//landing page related rights
	can_view_landing_pages: [0, 1, 2],
	can_edit_landing_pages: [0, 1, 2],

	//campaign related rights
	can_view_campaigns: [1, 2, 3],
	can_create_campaigns: [1, 2, 3],
	can_edit_campaigns: [1, 2, 3],

	can_view_email_campaigns: [1, 2, 3],
	can_edit_email_campaigns: [1, 2, 3],
	can_view_sms_campaigns: [1, 2, 3],
	can_edit_sms_campaigns: [1, 2, 3],
	can_view_whatsapp_campaigns: [1, 2, 3],
	can_edit_whatsapp_campaigns: [1, 2, 3],
	can_view_system_campaigns: [1, 2, 3],
	can_edit_system_campaigns: [1, 2, 3],
	can_view_voice_campaigns: [1, 2, 3],
	can_edit_voice_campaigns: [1, 2, 3],
	can_edit_training_campaigns: [1, 2, 3],

	can_edit_smtp_details: [1, 2, 3],

	can_view_phishing_reports: [1, 2, 3],
	can_edit_phishing_reports: [1, 2, 3],
};

// export const Services = ['phishing', 'training', 'vishing', 'smishing', 'whatsapp'];
export const Services = ['phishing', 'training', 'vishing', 'smishing', 'whatsapp'];

export const ServiceRights = {
	phishing: ['can_view_email_campaigns', 'can_edit_email_campaigns'],
	training: ['can_view_training_campaigns', 'can_edit_training_campaigns'],
	reporter: ['can_view_phishing_reports', 'can_edit_phishing_reports'],
	// vishing: ['can_view_voice_campaigns', 'can_edit_voice_campaigns'],
	// smishing: ['can_view_sms_campaigns', 'can_edit_sms_campaigns'],
	// whatsapp: ['can_view_whatsapp_campaigns', 'can_edit_whatsapp_campaigns'],
};

export const SYSTEM_TEMPLATE_TYPES = [
	{ name: 'User Onboarding', value: 'user_onboarding' },
	{ name: 'Training Assignment', value: 'training_assignment' },
	{ name: 'Training Reminder', value: 'training_reminder' },
	{ name: 'Training Escalation', value: 'training_escalation' },
	{ name: 'Reset Password', value: 'reset_password' },
];

export const CampaignTypeServiceMap = {
	email: ['phishing'],
	sms: ['smishing'],
	whatsapp: ['whatsapp'],
	voice: ['vishing'],
	training: ['training'],
	email_training: ['phishing', 'training'],
	reporter: ['reporter'],
};

export const MICROSOFT_APP_ID = process.env.REACT_APP_MICROSOFT_APP_ID || '528ed7a0-d131-46e5-9135-0e879e89f143';

export const MICROSOFT_READ_ALL_USER_SCOPE = 'openid+user.read.all+email+profile+user.read+offline_access';
export const MICROSOFT_READ_ALL_GROUPS_SCOPE = 'openid+group.read.all+email';
export const MICROSOFT_LOGIN_USER_SCOPE = 'openid+email+profile+user.read+offline_access';
